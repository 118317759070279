import { render, staticRenderFns } from "./Frontali.vue?vue&type=template&id=28ea65a8&scoped=true&"
import script from "./Frontali.vue?vue&type=script&lang=ts&"
export * from "./Frontali.vue?vue&type=script&lang=ts&"
import style0 from "./Frontali.vue?vue&type=style&index=0&id=28ea65a8&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "28ea65a8",
  null
  
)

export default component.exports